<template>
  <main>
    <section class="banner">
      <img src="@/assets/logo.png" alt="" />
      <h2>CODE-SMALL</h2>
    </section>
    <section class="navLinks">
      <ul>
        <li>Home</li>
        <li>Tutorials</li>
        <li>About</li>
        <li>Contact</li>
      </ul>
    </section>
  </main>
</template>
<script>
export default {
  name: "NavBar",
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: $primaryColor;

  & .banner {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.25rem;

    & img {
      height: 95%;
      border: $border;
      box-shadow: $boxShadow;
    }

    & h2 {
      font-family: $primaryFont;
      color: white;
      margin-left: 1rem;
    }
  }

  & .navLinks {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

ul {
  width: 100%;
  height: 1.75rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  background: $secondaryColor;
  li {
    margin: 0 1rem;
    background: none;
    color: white;
    cursor: pointer;
  }
}
</style>