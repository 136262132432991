<template>
  <main>
    <section class="navSection"><NavBar /></section>
    <section class="bodySection">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </section>
  </main>
</template>
<script>
import NavBar from "./NavBar.vue";
export default {
  name: "TutorialList",
  components: { NavBar },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
.navSection {
  width: 100%;
  height: 8rem;
}

.bodySection {
  width: 100%;
  height: 100%;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
</style>